import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pimg1 from '../../../images/clients/client_logo_1.png'
import pimg2 from '../../../images/clients/client_logo_2.png'
import pimg3 from '../../../images/clients/client_logo_3.webp'
import pimg4 from '../../../images/clients/client_logo_4.png'
import pimg5 from '../../../images/clients/client_logo_5.png'
import pimg6 from '../../../images/clients/client_logo_6.png'
import pimg7 from '../../../images/clients/client_logo_7.png';

const partners = [
  {
    pImg: pimg1,
  },
  {
    pImg: pimg2,
  },
  {
    pImg: pimg3,
  },
  {
    pImg: pimg4,
  },
  {
    pImg: pimg5,
  },
  {
    pImg: pimg6,
  },
  {
    pImg: pimg7,
  },
  {
    pImg: pimg4,
  },
];

var settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,

    responsive: [
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 350,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};



const FeaturePartners = (props) => {

    return (
      <div className='feature_partners_section'>
        <div className='container position-relative'>
          <div className='title_text text-white'>
            Já realizamos projetos para
          </div>
          <div className='client_logo_carousel'>
            <Slider {...settings}>
              {partners.map((partner, pitem) => (
                <div className='client_logo_item' key={pitem}>
                  <img src={partner.pImg} alt='Techco - Client Logo' />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
}

export default FeaturePartners;