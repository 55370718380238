import sImg1 from '../images/services/service_image_1.webp'
import sImg2 from '../images/services/service_image_2.webp'
import sImg3 from '../images/services/service_image_3.webp'
import sImg4 from '../images/services/service_image_4.webp'
import sImg5 from '../images/services/service_image_5.webp'

import icon1 from '../images/icons/icon_code.svg'
import icon2 from '../images/icons/icon_programming_tree.svg'
import icon3 from '../images/icons/icon_monitor_2.svg'
import icon4 from '../images/icons/icon_phone.svg'
import icon5 from '../images/icons/icon_bug.svg'
import icon6 from '../images/icons/icon_programming.svg'

import icon7 from '../images/icons/icon_analisis_2.svg'
import icon8 from '../images/icons/icon_process.svg'
import icon9 from '../images/icons/icon_chart_2.svg'
import icon10 from '../images/icons/icon_meeting.svg'
import icon11 from '../images/icons/icon_bulb_2.svg'
import icon12 from '../images/icons/icon_speed_miter.svg'


const Services = [
    {
        Id: '1',
        sImg: sImg1,
        title: 'Serviços de Gestão de TI',
        slug: 'Servicos-de-Gestao-de-TI',
        thumb1: 'Estratégia',
        thumb2: 'Consultoria',
        col: 'col-lg-6',
        description: 'Visite novos lugares para descobrir com um visto de turista. Nós entregamos seus documentos...'
    },
    {
        Id: '2',
        sImg: sImg2,
        title: 'Rastreamento de Dados e Segurança',
        slug: 'Rastreamento-de-Dados-e-Seguranca',
        thumb1: 'Gestão',
        thumb2: 'Transferência',
        col: 'col-lg-6',
        description: 'Desenvolvendo seu comércio, configurando novos canais de vendas. Seu visto está pronto...'
    },
    {
        Id: '3',
        sImg: sImg3,
        title: 'Desenvolvimento de Websites',
        slug: 'Desenvolvimento-de-Websites',
        thumb1: 'Página de Destino',
        thumb2: 'Plugins',
        col: 'col-lg-4',
        description: 'Desenvolvendo seu comércio, configurando novos canais de vendas. Seu visto está pronto...'
    },
    {
        Id: '4',
        sImg: sImg4,
        title: 'Soluções de Tecnologia Moderna',
        slug: 'Solucoes-de-Tecnologia-Moderna',
        thumb1: 'Consultoria',
        thumb2: 'Solução',
        col: 'col-lg-4',
        description: 'Embarcar em uma jornada de educação superior em um país estrangeiro abre portas para...'
    },
    {
        Id: '5',
        sImg: sImg5,
        title: 'Serviços de Design UI/UX',
        slug: 'Servicos-de-Design-UI-UX',
        thumb1: 'Website',
        thumb2: 'Aplicativo Mobile',
        col: 'col-lg-4',
        description: 'Orientação especializada para uma jornada de imigração sem falhas. Orientação especializada...'
    },
    {
        Id: '6',
        sImg: icon1,
        title: 'Desenvolvimento de Software Sob Medida',
        slug: 'Desenvolvimento-de-Software-Sob-Medida',
        features: ['Design de arquitetura de software', 'Serviços de integração de sistemas', 'Serviços de migração de dados', 'Modernização de sistemas e aplicativos legados', 'Automatização de tarefas utilizando ferramentas de RPA']
    },
    {
        Id: '7',
        sImg: icon3,
        title: 'Design e Desenvolvimento de Aplicações Web',
        slug: 'Design-e-Desenvolvimento-de-Aplicacoes-Web',
        features: ['Serviços de desenvolvimento de aplicativos web', 'Serviços de desenvolvimento de portais web', 'Serviços de desenvolvimento de websites', 'Desenvolvimento de sistemas corporativos']
    },
    {
        Id: '8',
        sImg: icon4,
        title: 'Design e Desenvolvimento de Aplicativos Mobile',
        slug: 'Design-e-Desenvolvimento-de-Aplicativos-Mobile',
        features: ['Serviços de desenvolvimento Android', 'Serviços de desenvolvimento iOS', 'Serviços de design de aplicativos mobile', 'Desenvolvimento de aplicativos mobile para empresas']
    },
    {
        Id: '11',
        sImg: icon7,
        title: 'Planejamento Estratégico e Execução',
        slug: 'Planejamento-Estrategico-e-Execucao',
        description: 'Nossos serviços de consultoria são personalizados para atender às necessidades e metas únicas de cada cliente, garantindo alinhamento preciso com seus objetivos de negócios.'
    },
    {
        Id: '12',
        sImg: icon8,
        title: 'Otimização de Processos de Negócio',
        slug: 'Otimizacao-de-Processos-de-Negocio',
        description: 'Nossos serviços de consultoria são personalizados para atender às necessidades e metas únicas de cada cliente, garantindo alinhamento preciso com seus objetivos de negócios.'
    },
    {
        Id: '13',
        sImg: icon9,
        title: 'Consultoria de Transformação Digital',
        slug: 'Consultoria-de-Transformacao-Digital',
        description: 'Nossos serviços de consultoria são personalizados para atender às necessidades e metas únicas de cada cliente, garantindo alinhamento preciso com seus objetivos de negócios.'
    },
    {
        Id: '14',
        sImg: icon10,
        title: 'Planejamento Estratégico e Execução',
        slug: 'Planejamento-Estrategico-e-Execucao',
        description: 'Nossos serviços de consultoria são personalizados para atender às necessidades e metas únicas de cada cliente, garantindo alinhamento preciso com seus objetivos de negócios.'
    },
    {
        Id: '15',
        sImg: icon11,
        title: 'Soluções de Gestão de Mudanças',
        slug: 'Solucoes-de-Gestao-de-Mudancas',
        description: 'Nossos serviços de consultoria são personalizados para atender às necessidades e metas únicas de cada cliente, garantindo alinhamento preciso com seus objetivos de negócios.'
    },
    {
        Id: '16',
        sImg: icon12,
        title: 'Métricas de Desempenho e Desenvolvimento de KPIs',
        slug: 'Metricas-de-Desempenho-e-Desenvolvimento-de-KPIs',
        description: 'Nossos serviços de consultoria são personalizados para atender às necessidades e metas únicas de cada cliente, garantindo alinhamento preciso com seus objetivos de negócios.'
    }
]    

export default Services;