import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import HomePage2 from '../HomePage2/HomePage2';

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage2 />} />
          <Route path="home" element={<HomePage2 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AllRoute;
