
import React, { useState } from 'react';
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_space_4.svg'
import shape2 from '../../../images/shapes/shape_angle_3.webp'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
      <section className='faq_section section_decoration' id="faq">
        <div className='container'>
          <div className='heading_block text-center'>
            <div
              className='heading_focus_text has_underline d-inline-flex'
              style={{ backgroundImage: `url(${Bg})` }}
            >
              F.A.Q.
            </div>
            <h2 className='heading_text mb-0'>
              Tem alguma <mark>Dúvida?</mark>
            </h2>
          </div>

          <div className='faq_accordion accordion' id='faq_accordion'>
            <Accordion
              open={open}
              toggle={toggle}
              className='accordion'
              id='service_process_faq'
            >
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='1'>
                  Quais serviços a PartnerCode oferece?
                </AccordionHeader>
                <AccordionBody accordionId='1' className='acc_body'>
                  <p>
                    Oferecemos serviços completos de desenvolvimento de
                    software, incluindo criação de aplicativos web e mobile,
                    soluções SaaS, automação de processos, integração de
                    sistemas e consultoria em tecnologia. Nossos serviços
                    abrangem desde a fase de discovery até a implantação final e
                    suporte contínuo.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='2'>
                  Trabalhamos com desenvolvimento de software sob demanda?
                </AccordionHeader>
                <AccordionBody accordionId='2' className='acc_body'>
                  <p>
                    Sim, desenvolvemos soluções personalizadas sob medida para
                    atender às necessidades específicas de cada cliente.
                    Avaliamos o projeto, discutimos os requisitos e criamos um
                    software que se alinha ao seu negócio.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='3'>
                  Quais tecnologias utilizamos?
                </AccordionHeader>
                <AccordionBody accordionId='3' className='acc_body'>
                  <p>
                    Trabalhamos com uma variedade de tecnologias modernas como
                    .NET Core, React, React Native, JavaScript, C#, entre
                    outras. Escolhemos as tecnologias mais adequadas para cada
                    projeto, garantindo escalabilidade, segurança e eficiência.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='4'>
                  Como é o processo de desenvolvimento de um projeto na
                  PartnerCode?
                </AccordionHeader>
                <AccordionBody accordionId='4' className='acc_body'>
                  <p>
                    Nosso processo segue etapas bem definidas: começamos com a
                    Fase de Discovery, onde entendemos as necessidades do
                    cliente, seguimos para o Planejamento e Design,
                    Desenvolvimento, Testes e QA, e finalizamos com Deployment e
                    Homologação. Cada fase é projetada para garantir o sucesso
                    do projeto.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='5'>
                  Qual é o prazo médio para entrega de um projeto?
                </AccordionHeader>
                <AccordionBody accordionId='5' className='acc_body'>
                  <p>
                    O prazo varia dependendo da complexidade e do escopo do
                    projeto. Após a Fase de Discovery e o Planejamento,
                    conseguimos fornecer uma estimativa precisa. Nos esforçamos
                    para cumprir os prazos acordados, mantendo a qualidade e
                    eficiência.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='6'>
                  Como posso acompanhar o andamento do meu projeto?
                </AccordionHeader>
                <AccordionBody accordionId='6' className='acc_body'>
                  <p>
                    Mantemos uma comunicação transparente ao longo de todo o
                    processo. Utilizamos ferramentas de gestão de projetos que
                    permitem ao cliente acompanhar o progresso em tempo real,
                    participar de revisões e fazer ajustes, se necessário.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='7'>
                  Oferecemos suporte após a entrega do projeto?
                </AccordionHeader>
                <AccordionBody accordionId='7' className='acc_body'>
                  <p>
                    Sim, oferecemos serviços de manutenção e suporte
                    pós-implantação para garantir que o software continue
                    funcionando corretamente e adaptado a novas necessidades.
                    Também oferecemos planos de suporte contínuo para evolução
                    do sistema.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='8'>
                  Qual é o custo de um projeto de desenvolvimento de software?
                </AccordionHeader>
                <AccordionBody accordionId='8' className='acc_body'>
                  <p>
                    O custo de um projeto depende de diversos fatores, como a
                    complexidade, o escopo, as tecnologias utilizadas e os
                    prazos. Após uma análise detalhada das suas necessidades,
                    fornecemos uma proposta personalizada com uma estimativa de
                    custo.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='9'>
                  Trabalhamos com metodologias ágeis?
                </AccordionHeader>
                <AccordionBody accordionId='9' className='acc_body'>
                  <p>
                    Sim, seguimos metodologias ágeis como Scrum e Kanban para
                    garantir flexibilidade, adaptação rápida às mudanças e
                    entregas contínuas. Isso permite uma maior colaboração com
                    nossos clientes ao longo do desenvolvimento.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='10'>
                  Como posso iniciar um projeto com a PartnerCode?
                </AccordionHeader>
                <AccordionBody accordionId='10' className='acc_body'>
                  <p>
                    Para iniciar um projeto, basta entrar em contato conosco
                    através de nosso site, email ou telefone. Agendaremos uma reunião para
                    discutir suas necessidades, objetivos e expectativas,
                    iniciando o processo de discovery e planejamento.
                  </p>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className='decoration_item shape_image_1'>
          <img src={shape1} alt='Techco Shape' />
        </div>
        <div className='decoration_item shape_image_2'>
          <img src={shape2} alt='Techco Shape Angle' />
        </div>
      </section>
    );
};
export default FaqSection;
