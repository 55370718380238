import React from 'react';
import { Link } from 'react-router-dom'
import Project from '../../../api/project'
import Bg from '../../../images/backgrounds/bg_image_2.webp'
import Bg2 from '../../../images/shapes/shape_bg_1.webp'
import Bg3 from '../../../images/shapes/shape_title_under_line.svg'
import about1 from '../../../images/about/about_image_6.webp'
import aIcon1 from '../../../images/avatar/avatar_1.webp'
import aIcon2 from '../../../images/avatar/avatar_2.webp'
import aIcon3 from '../../../images/avatar/avatar_3.webp'
import aIcon4 from '../../../images/icons/icon_global.svg'
import aIcon5 from '../../../images/shapes/shape_line.webp'
import shape1 from '../../../images/shapes/shape_space_2.svg'


const About = (props) => {

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  }

  return (
    <section
      className='about_and_case_section section_space section_decoration bg-dark'
      style={{ backgroundImage: `url(${Bg})` }}
      id="goals"
    >
      <div className='container'>
        <div className='row align-items-center justify-content-lg-between'>
          <div className='col-lg-12'>
            <div className='about_content'>
              <div className='heading_block mb-0 text-white'>
                <div
                  className='heading_focus_text has_underline d-inline-flex'
                  style={{ backgroundImage: `url(${Bg3})` }}
                >
                  Sobre nós
                </div>
                <h2 className='heading_text'>
                  Nossa <mark>Missão e Meta</mark>
                </h2>
                <p className='heading_description mb-0'>
                  Na <mark>PartnerCode</mark>, somos apaixonados por tecnologia
                  e comprometidos em fornecer soluções inovadoras para ajudar
                  empresas a alcançar seus objetivos. Combinamos expertise
                  técnica com uma abordagem estratégica para desenvolver
                  soluções personalizadas que atendem às necessidades exclusivas
                  de cada cliente.
                </p>
                <br />
                <p className='heading_description mb-0'>
                  Nossa missão é transformar negócios através da tecnologia,
                  proporcionando serviços de desenvolvimento de software,
                  consultoria em TI e inovação digital. Com uma equipe altamente
                  qualificada e focada em resultados, trabalhamos lado a lado
                  com nossos clientes para impulsionar o crescimento, melhorar a
                  eficiência e criar valor duradouro.
                </p>
                <br />
                <p className='heading_description mb-0'>
                  Acreditamos que cada projeto é uma oportunidade de agregar
                  valor real, e é por isso que estamos sempre buscando as
                  melhores práticas, tecnologias de ponta e estratégias que
                  garantam o sucesso dos nossos clientes. Seja modernizando
                  sistemas legados, desenvolvendo novas plataformas ou
                  integrando tecnologias disruptivas, estamos prontos para levar
                  sua empresa mais longe.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='pt-170' id="projects">
          <div className='heading_block text-center text-white'>
            <div
              className='heading_focus_text has_underline d-inline-flex'
              style={{ backgroundImage: `url(${Bg3})` }}
            >
              Case Studies
            </div>
            <h2 className='heading_text mb-0'>
              Nossos últimos <mark>Projetos</mark>
            </h2>
          </div>

          <div className='case_studies_wrapper'>
            {Project.slice(6, 9).map((project, prj) => (
              <div className='case_study_block' key={prj}>
                <div className='case_study_image'>
                  <img src={project.pImg} alt='Techco - Cases' />
                </div>
                <div className='case_study_content'>
                  <ul className='category_list unordered_list text-uppercase'>
                    <li>
                      <span>{project.sub}</span>
                    </li>
                  </ul>
                  <h3 className='case_title'>
                    <h2>
                      {project.title}
                    </h2>
                  </h3>
                  <p>{project.description}</p>
                  <ul className='icon_list unordered_list'>
                    <li>
                      <span className='icon_list_text'>
                        <strong className='text-dark'>Segmento:</strong>{' '}
                        {project.Industry}
                      </span>
                    </li>
                    <li>
                      <span className='icon_list_text'>
                        <strong className='text-dark'>País:</strong>{' '}
                        {project.Country}
                      </span>
                    </li>
                  </ul>
                  <ul
                    className='case_technologies unordered_list'
                    data-text='Tecnologias Core:'
                  >
                    <li>
                      <img src={project.Technologies1} alt='Angular' />
                    </li>
                    <li>
                      <img src={project.Technologies2} alt='Elephent' />
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='decoration_item shape_image_1'>
        <img src={shape1} alt='Techco Shape' />
      </div>
    </section>
  );
}

export default About;