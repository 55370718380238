import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

const ContactForm = () => {
  const [forms, setForms] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
    message: '',
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: 'errorMessage',
      messages: {
        required: 'Este campo é obrigatório.',
      },
    })
  );
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (validator.allValid()) {
      setIsLoading(true);
      setErrorMessage('');
      setSuccessMessage('');

      try {
        const response = await fetch(
          'https://partnercode.com.br/envio-email/enviar-contato',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(forms),
          }
        );

        if (response.ok) {
          setSuccessMessage(
            'Obrigado por entrar em contato! Nossa equipe retornará em breve.'
          );
          setForms({
            name: '',
            email: '',
            company: '',
            phone: '',
            message: '',
          });
        } else {
          throw new Error(
            'Erro ao enviar a mensagem. Por favor, tente novamente.'
          );
        }
      } catch (error) {
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      validator.showMessages();
    }
  };

  return (
    <form className='xb-item--form contact-from' onSubmit={submitHandler}>
      <div className='row'>
        <div className='col-md-6'>
          <div className='form-group'>
            <label className='input_title' htmlFor='input_name'>
              <i className='fa-regular fa-user'></i>
            </label>
            <input
              value={forms.name}
              type='text'
              name='name'
              className='form-control'
              onBlur={changeHandler}
              onChange={changeHandler}
              placeholder='Seu nome'
            />
            {validator.message('nome', forms.name, 'required|alpha_space')}
          </div>
        </div>
        <div className='col-md-6'>
          <div className='form-group'>
            <label className='input_title' htmlFor='input_email'>
              <i className='fa-regular fa-envelope'></i>
            </label>
            <input
              value={forms.email}
              type='email'
              name='email'
              className='form-control'
              onBlur={changeHandler}
              onChange={changeHandler}
              placeholder='Seu e-mail'
            />
            {validator.message('e-mail', forms.email, 'required|email')}
          </div>
        </div>
        <div className='col-md-6'>
          <div className='form-group'>
            <label className='input_title' htmlFor='input_phone'>
              <i className='fa-regular fa-phone-volume'></i>
            </label>
            <input
              value={forms.phone}
              type='text'
              name='phone'
              className='form-control'
              onBlur={changeHandler}
              onChange={changeHandler}
              placeholder='Seu telefone'
            />
            {validator.message('telefone', forms.phone, 'required|phone')}
          </div>
        </div>
        <div className='col-md-6'>
          <div className='form-group'>
            <label className='input_title' htmlFor='input_company'>
              <i className='fa-regular fa-globe'></i>
            </label>
            <input
              value={forms.company}
              type='text'
              name='company'
              className='form-control'
              onBlur={changeHandler}
              onChange={changeHandler}
              placeholder='O nome da sua Empresa'
            />
            {validator.message('empresa', forms.company, 'required')}
          </div>
        </div>
        <div className='col-12'>
          <div className='form-group'>
            <label className='input_title' htmlFor='input_textarea'>
              <i className='fa-regular fa-comments'></i>
            </label>
            <textarea
              onBlur={changeHandler}
              onChange={changeHandler}
              value={forms.message}
              name='message'
              className='form-control'
              placeholder='Como podemos te ajudar?'
            ></textarea>
            {validator.message('mensagem', forms.message, 'required')}
          </div>
          <button
            type='submit'
            className='btn btn-primary'
            disabled={isLoading}
          >
            <span className='btn_label' data-text='Vamos Começar!'>
              {isLoading ? 'Enviando...' : 'Enviar Mensagem'}
            </span>
            <span className='btn_icon'>
              <i className='fa-solid fa-arrow-up-right'></i>
            </span>
          </button>
        </div>
      </div>
      {successMessage && <div className='successMessage'>{successMessage}</div>}
      {errorMessage && <div className='errorMessage'>{errorMessage}</div>}
    </form>
  );
};

export default ContactForm;
