import React from 'react'
import { Link } from 'react-router-dom'
import Bg from '../../../images/shapes/bg_pattern_3.svg'
import Bg2 from '../../../images/shapes/shape_space_6.svg'
import icon1 from '../../../images/shapes/shape_space_2.svg'
import icon2 from '../../../images/icons/icon_mail_2.svg'
import Slider from "react-slick";
import { Link as ScrollLink } from 'react-scroll';

const ClickHandler = () => {
  window.scrollTo(10, 0);
}

const SubmitHandler = (e) => {
  e.preventDefault()
}

var settings = {
  dots: false,
  infinite: true,
  speed: 3000,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: "linear",
  arrows: false,

  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Footer = (props) => {
  return (
    <footer className="site_footer footer_layout_2 section_decoration" style={{ backgroundImage: `url(${Bg})` }}>
      <div className="decoration_item shape_image_1">
        <img src={icon1} alt="Techco Shape" />
      </div>
      <div className="container">
        <div className="service_pill_carousel swiper">
          <Slider {...settings}>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Aplicações Web Customizadas</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Desenvolvimento de Apps</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Desenvolvimento Web</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Soluções de Software</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Sistemas Corporativos</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Integrações de Sistemas</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Serviços de migração de dados</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Modernização de sistemas legados</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Automatização de tarefas e processos</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Serviços de TI</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Design e arquitetura de software</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Consultoria em Tecnologia</span>
              </a>
            </div>
          </Slider>
        </div>
        <div className="footer_main_content">
          <div className="row justify-content-lg-between">
            <div className="col-lg-2 col-md-6">
              <div className="footer_widget">
                <h3 className="footer_info_title">PartnerCode</h3>
                <ul className="icon_list unordered_list_block">
                  <li>
                    <ScrollLink to="services" style={{ cursor: 'pointer' }}>
                      <span className="icon_list_text">
                        Nossos Serviços
                      </span>
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink to="goals" style={{ cursor: 'pointer' }}>
                      <span className="icon_list_text">
                        Missão e Meta
                      </span>
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink to="projects" style={{ cursor: 'pointer' }}>
                      <span className="icon_list_text">
                        Projetos
                      </span>
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink to="methodology" style={{ cursor: 'pointer' }}>
                      <span className="icon_list_text">
                        Nossa Metodologia
                      </span>
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink to="faq" style={{ cursor: 'pointer' }}>
                      <span className="icon_list_text">
                        FAQ
                      </span>
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink to="contact" style={{ cursor: 'pointer' }}>
                      <span className="icon_list_text">
                        Contato
                      </span>
                    </ScrollLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer_widget">
                <h3 className="footer_info_title">Expertise</h3>
                <ul className="icon_list unordered_list_block">
                  <li>
                    <span className="icon_list_text">
                      Desenvolvimento Web
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text">
                      Aplicativos Mobile
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text">
                      Integrações de Sistemas
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text">
                    Design e Arquitetura
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer_widget">
                <h3 className="footer_info_title">Industrias</h3>
                <ul className="icon_list unordered_list_block">
                  <li>
                    <span className="icon_list_text">
                      Financeira
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text">
                      Farmacêutica
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text">
                      Tecnologia
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text">
                      Imobiliária
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text">
                      Médica
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_text">
                      Energética
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer_widget pe-lg-3">
                <h2 className="footer_info_title">Newsletter</h2>
                <p>
                  Inscreva-se na Newsletter semanal da PartnerCode para obter as atualizações mais recentes.
                </p>
                <form className="footer_newslatter_2" onSubmit={SubmitHandler}>
                  <label htmlFor="footer_mail_input">
                    <img src={icon2} alt="Mail SVG Icon" />
                  </label>
                  <input id="footer_mail_input" type="email" name="email" placeholder="Informe o seu e-mail" />
                  <button type="submit">Enviar</button>
                </form>



                <ul className="social_icons_block unordered_list">
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="fa-brands fa-facebook-f"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="fa-brands fa-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="fa-brands fa-youtube"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom" style={{ backgroundImage: `url(${Bg2})` }}>
        <div className="container d-md-flex align-items-md-center justify-content-md-between">
          <p className="copyright_text m-0">
            Copyright © {new Date().getFullYear()} PartnerCode, Todos os direitos reservados.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;