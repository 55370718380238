import pImg1 from '../images/portfolio/portfolio_item_image_1.webp'
import pImg2 from '../images/portfolio/portfolio_item_image_2.webp'
import pImg3 from '../images/portfolio/portfolio_item_image_3.webp'
import pImg4 from '../images/case/case_image_1.jpg'
import pImg5 from '../images/case/case_image_2.png'
import pImg6 from '../images/case/case_image_3.png'

import pImg7 from '../images/portfolio/portfolio_item_image_4.webp'
import pImg8 from '../images/portfolio/portfolio_item_image_5.webp'
import pImg9 from '../images/portfolio/portfolio_item_image_6.webp'
import pImg10 from '../images/portfolio/portfolio_item_image_7.webp'
import pImg11 from '../images/portfolio/portfolio_item_image_8.webp'
import pImg12 from '../images/portfolio/portfolio_item_image_9.webp'
import pImg13 from '../images/portfolio/portfolio_item_image_10.webp'
import pImg14 from '../images/portfolio/portfolio_item_image_11.webp'


import tImg1 from '../images/case/icon_angular.svg'
import tImg2 from '../images/case/icon_elephent.svg'
import tImg3 from '../images/case/icon_netcore.svg'
import tImg4 from '../images/case/vuejs.svg'
import tImg5 from '../images/case/icon_python.svg'
import tImg6 from '../images/icons/icon_sqlserver.png';


const Project = [
  {
    Id: '1',
    pImg: pImg1,
    title: 'Mobile App Design',
    slug: 'Mobile-App-Design',
    sub: 'App Design',
    description:
      'Our all-encompassing IELTS Coaching curriculum encompasses every aspect...',
  },
  {
    Id: '2',
    pImg: pImg2,
    title: 'TOEFL Coaching',
    slug: 'TOEFL-Coaching',
    sub: '',
    description:
      'Our experienced coaches are not just educators; they are partners in your journey..',
  },
  {
    Id: '3',
    pImg: pImg3,
    title: 'Dashboard Design',
    slug: 'Dashboard-Design',
    sub: 'Web Design',
    description:
      'The contents of the citizenship test typically cover a range of subjects ...',
  },
  {
    Id: '4',
    pImg: pImg1,
    title: 'Mobile App Design',
    slug: 'Mobile-App-Design',
    sub: 'App Design',
    description:
      'Our all-encompassing IELTS Coaching curriculum encompasses every aspect...',
  },
  {
    Id: '5',
    pImg: pImg2,
    title: 'TOEFL Coaching',
    slug: 'TOEFL-Coaching',
    sub: '',
    description:
      'Our experienced coaches are not just educators; they are partners in your journey..',
  },
  {
    Id: '6',
    pImg: pImg3,
    title: 'Dashboard Design',
    slug: 'Dashboard-Design',
    sub: 'Web Design',
    description:
      'The contents of the citizenship test typically cover a range of subjects ...',
  },
  {
    Id: '7',
    pImg: pImg4,
    title: 'Portal de Hospedagem',
    slug: 'Portal de Hospedagem',
    sub: 'Sistema Corporativo',
    Industry: 'Tecnologia',
    Country: 'Brasil, São Paulo',
    Technologies1: tImg3,
    Technologies2: tImg6,
    description:
      'Desenvolvimento de um portal web que possibilita a contratação de diversos serviços de tecnologia, como hospedagem de sites, e-mail corporativo, DNS, backup online e máquinas virtuais. Além disso, foi implementado um sistema completo de gestão de clientes e controle financeiro, garantindo uma administração eficiente de todas as operações.',
  },
  {
    Id: '8',
    pImg: pImg5,
    title: 'Sistema de Integração de Estoque',
    slug: 'Sistema de Integração de Estoque',
    sub: 'Plataforma de Integração',
    Industry: 'Farmaceutico',
    Country: 'Brasil, São Paulo',
    Technologies1: tImg3,
    Technologies2: tImg6,
    description:
      'Implementação de um sistema que automatiza a integração de dados de estoque e preços entre o ERP do cliente e e-commerces como Magento, OpenCart e Plataforma Tray. O sistema permite a configuração personalizada e o agendamento das sincronizações, além de oferecer monitoramento em tempo real do andamento e status das integrações.',
  },
  {
    Id: '9',
    pImg: pImg6,
    title: 'Sistema de Financiamento',
    slug: 'Sistema de Financiamento',
    sub: 'Financeiro',
    Industry: 'Automotivo',
    Country: 'Brasil, São Paulo',
    Technologies1: tImg4,
    Technologies2: tImg3,
    description:
      'Sistema para simulação e solicitação de financiamento de veículos, que permite aos usuários calcular de forma clara e precisa todos os custos e parcelas de veículos e caminhões. Com uma interface intuitiva, os clientes podem facilmente realizar simulações personalizadas, enquanto a plataforma conta com um robusto backoffice, que facilita a análise das solicitações. Nesse ambiente administrativo, as equipes podem aprovar ou rejeitar financiamentos de maneira eficiente, garantindo um processo ágil e seguro para os usuários.',
  },
  {
    Id: '10',
    pImg: pImg7,
    title:
      'Driving Digital Transformation Explore the Depth of Our IT Projects',
    slug: 'Driving-Digital-Transformation-Explore-the-Depth-of-Our-IT Projects',
    thumb1: 'Logo Design',
    thumb2: 'Finance',
    category: 'technology',
  },
  {
    Id: '17',
    pImg: pImg8,
    title: 'Explore Our IT Solutions Portfolio for Public Sector Organizations',
    slug: 'Explore-Our-IT-Solutions',
    thumb1: 'App Design',
    thumb2: 'Public',
    category: 'helpdesk',
  },
  {
    Id: '18',
    pImg: pImg9,
    title: 'Innovative Solutions Showcase the Diversity of Our IT Portfolio',
    slug: 'Innovative-Solutions-Showcase-the-Diversity-of-Our-IT-Portfolio',
    thumb1: 'Card Design',
    thumb2: 'Transpiration',
    category: 'analysis',
  },
  {
    Id: '19',
    pImg: pImg10,
    title: 'Tech Triumphs Celebrating Our Achievements in IT Solutions',
    slug: 'Tech-Triumphs-Celebrating-Our-Achievements-in-IT-Solutions',
    thumb1: 'Web Design',
    thumb2: 'Logistic',
    category: 'technology',
  },
  {
    Id: '20',
    pImg: pImg11,
    title:
      'Revolutionizing IT Strategies A Closer Look at Our Dynamic IT Solutions',
    slug: 'Revolutionizing-IT-Strategies-A-Closer-Look-at-Our-Dynamic-IT-Solutions',
    thumb1: 'Web Design',
    thumb2: 'Fution',
    category: 'analysis',
  },
  {
    Id: '21',
    pImg: pImg12,
    title: 'Cloud Migration and Integration Project IT Solutions Portfolio',
    slug: 'Cloud-Migration-and-Integration-Project-IT-Solutions-Portfolio',
    thumb1: 'Web Design',
    thumb2: 'Energy',
    category: 'helpdesk',
  },
  {
    Id: '22',
    pImg: pImg13,
    title: 'Pioneering Progress Exploring the Evolution and Impact of',
    slug: 'Pioneering-Progress-Exploring-the-Evolution-and-Impact-of',
    thumb1: 'Web Design',
    thumb2: 'Health',
    category: 'marketing',
  },
  {
    Id: '23',
    pImg: pImg14,
    title: 'Unlocking Potential Explore Our Comprehensive IT Portfolio',
    slug: 'Unlocking-Potential-Explore-Our-Comprehensive-IT-Portfolio',
    thumb1: 'Web Design',
    thumb2: 'Industry',
    category: 'technology',
  },
];

export default Project;