import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import cnIcon1 from '../../../images/icons/icon_php.svg';
import cnIcon2 from '../../../images/icons/icon_javascript.svg';
import cnIcon3 from '../../../images/case/icon_elephent.svg';
import cnIcon4 from '../../../images/icons/icon_dotnet.png';
import cnIcon5 from '../../../images/icons/icon_typescript.svg';
import cnIcon6 from '../../../images/icons/icon_node.png';
import cnIcon7 from '../../../images/icons/icon_python.svg';
import cnIcon8 from '../../../images/icons/icon_java.svg';
import cnIcon9 from '../../../images/icons/icon_angular.webp';
import cnIcon10 from '../../../images/icons/icon_sqlserver.png';
import cnIcon11 from '../../../images/icons/icon_react_js.svg';
import cnIcon12 from '../../../images/icons/icon_oracle.png';
import cnIcon13 from '../../../images/icons/icon_mariadb.png';
import cnIcon14 from '../../../images/icons/icon_mongodb.png';
import cnIcon15 from '../../../images/icons/icon_power_automate.png';
import cnIcon16 from '../../../images/icons/icon_react_js.svg';
import cnIcon17 from '../../../images/icons/icon_flutter.png';
import Bg from '../../../images/shapes/shape_title_under_line.svg';

const TechnologyList = [
  {
    Id: '1',
    category: 'web',
    sIcon: cnIcon1,
    title: 'PHP',
  },
  {
    Id: '2',
    category: 'web',
    sIcon: cnIcon2,
    title: 'JavaScript',
  },
  {
    Id: '3',
    category: 'database',
    sIcon: cnIcon3,
    title: 'PostgreSQL',
  },
  {
    Id: '4',
    category: 'web',
    sIcon: cnIcon4,
    title: 'DotNet',
  },
  {
    Id: '5',
    category: 'web',
    sIcon: cnIcon5,
    title: 'Typescript',
  },
  {
    Id: '6',
    category: 'web',
    sIcon: cnIcon6,
    title: 'Node JS',
  },
  {
    Id: '7',
    category: 'others',
    sIcon: cnIcon7,
    title: 'Python',
  },
  {
    Id: '8',
    category: 'web',
    sIcon: cnIcon8,
    title: 'Java',
  },
  {
    Id: '9',
    category: 'web',
    sIcon: cnIcon9,
    title: 'Angular',
  },
  {
    Id: '10',
    category: 'database',
    sIcon: cnIcon10,
    title: 'SQL Server',
  },
  {
    Id: '11',
    category: 'web',
    sIcon: cnIcon11,
    title: 'React Js',
  },
  {
    Id: '12',
    category: 'database',
    sIcon: cnIcon12,
    title: 'Oracle',
  },
  {
    Id: '13',
    category: 'database',
    sIcon: cnIcon13,
    title: 'MariaDB',
  },
  {
    Id: '14',
    category: 'database',
    sIcon: cnIcon14,
    title: 'MongoDB',
  },
  {
    Id: '15',
    category: 'automation',
    sIcon: cnIcon15,
    title: 'Power Automate Desktop',
  },
  {
    Id: '16',
    category: 'mobile',
    sIcon: cnIcon16,
    title: 'React Native',
  },
  {
    Id: '17',
    category: 'mobile',
    sIcon: cnIcon17,
    title: 'Flutter',
  },
];

const TechnologySection = (props) => {
  const [activeTab, setActiveTab] = useState('web');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className='section_space'>
      <div className='heading_block text-center'>
        <div
          className='heading_focus_text has_underline d-inline-flex'
          style={{ backgroundImage: `url(${Bg})` }}
        >
          Ferramentas
        </div>
        <h2 className='heading_text mb-0'>
          Algumas <mark>tecnologias</mark> que utilizamos
        </h2>
      </div>

      <div className='tab_block_wrapper'>
        <Nav tabs className='nav justify-content-center'>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'web' })}
              onClick={() => {
                toggle('web');
              }}
            >
              Web
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'database' })}
              onClick={() => {
                toggle('database');
              }}
            >
              Banco de Dados
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'automation' })}
              onClick={() => {
                toggle('automation');
              }}
            >
              Automação
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'mobile' })}
              onClick={() => {
                toggle('mobile');
              }}
            >
              Aplicativos Mobile
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'others' })}
              onClick={() => {
                toggle('others');
              }}
            >
              Outras Ferramentas
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId='web'>
            <div className='web_development_technologies row justify-content-center'>
              {TechnologyList.filter(
                (technology) => technology.category === activeTab
              ).map((technology, cnt) => (
                <div className='col-lg-2 col-md-3 col-sm-4 col-6' key={cnt}>
                  <div className='iconbox_block text-center p-0 shadow-none bg-transparent'>
                    <div className='iconbox_icon'>
                      <img src={technology.sIcon} alt='' />
                    </div>
                    <div className='iconbox_content'>
                      <h3 className='iconbox_title mb-0'>{technology.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tabId='database'>
            <div className='web_development_technologies row justify-content-center'>
              {TechnologyList.filter(
                (technology) => technology.category === activeTab
              ).map((technology, cnt) => (
                <div className='col-lg-2 col-md-3 col-sm-4 col-6' key={cnt}>
                  <div className='iconbox_block text-center p-0 shadow-none bg-transparent'>
                    <div className='iconbox_icon'>
                      <img src={technology.sIcon} alt='' />
                    </div>
                    <div className='iconbox_content'>
                      <h3 className='iconbox_title mb-0'>{technology.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tabId='automation'>
            <div className='web_development_technologies row justify-content-center'>
              {TechnologyList.filter(
                (technology) => technology.category === activeTab
              ).map((technology, cnt) => (
                <div className='col-lg-2 col-md-3 col-sm-4 col-6' key={cnt}>
                  <div className='iconbox_block text-center p-0 shadow-none bg-transparent'>
                    <div className='iconbox_icon'>
                      <img src={technology.sIcon} alt='' />
                    </div>
                    <div className='iconbox_content'>
                      <h3 className='iconbox_title mb-0'>{technology.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tabId='mobile'>
            <div className='web_development_technologies row justify-content-center'>
              {TechnologyList.filter(
                (technology) => technology.category === activeTab
              ).map((technology, cnt) => (
                <div className='col-lg-2 col-md-3 col-sm-4 col-6' key={cnt}>
                  <div className='iconbox_block text-center p-0 shadow-none bg-transparent'>
                    <div className='iconbox_icon'>
                      <img src={technology.sIcon} alt='' />
                    </div>
                    <div className='iconbox_content'>
                      <h3 className='iconbox_title mb-0'>{technology.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tabId='others'>
            <div className='web_development_technologies row justify-content-center'>
              {TechnologyList.filter(
                (technology) => technology.category === activeTab
              ).map((technology, cnt) => (
                <div className='col-lg-2 col-md-3 col-sm-4 col-6' key={cnt}>
                  <div className='iconbox_block text-center p-0 shadow-none bg-transparent'>
                    <div className='iconbox_icon'>
                      <img src={technology.sIcon} alt='' />
                    </div>
                    <div className='iconbox_content'>
                      <h3 className='iconbox_title mb-0'>{technology.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default TechnologySection;
