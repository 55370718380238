import { useRef, useEffect } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import icon1 from '../../../images/icons/icon_c.svg'
import icon2 from '../../../images/icons/icon_g2.svg'
import tImg1 from '../../../images/avatar/avatar_5.webp'
import tImg2 from '../../../images/avatar/avatar_6.webp'
import tImg3 from '../../../images/avatar/avatar_7.webp'

import clogo from '../../../images/clients/client_logo_8.webp'
import flag from '../../../images/flag/ukraine_flag.webp'



const testimonial = [
    {
        id: '01',
        Title: 'Redução de Custos',
        Des: "Ao optar trabalhar conosco você pode reduzir significativamente os custos, eliminando a necessidade de manter uma equipe interna. Você paga apenas pelo que precisa, permitindo uma gestão financeira mais eficiente, especialmente em projetos de curta duração.",
    },
    {
        id: '02',
        Title: 'Expertise Técnica',
        Des: "Nós oferecemos uma equipe especializada com profundo conhecimento em diversas tecnologias. Isso garante que seu projeto siga os melhores padrões do mercado, além de aproveitar as últimas inovações e tendências que podem ser desafiadoras para equipes internas acompanhar.",
    },
    {
        id: '03',
        Title: 'Foco no Core Business',
        Des: "Ao nos confiar o desenvolvimento do seu backlog, sua equipe interna pode concentrar-se em suas atividades e estratégias principais. Essa redução de distrações permite que sua empresa direcione mais energia para o crescimento e a inovação em sua área de atuação. Com isso, não apenas a eficiência geral aumenta, mas também a capacidade de atender melhor seus clientes e explorar novas oportunidades de negócio.",
    },
    {
        id: '04',
        Title: 'Suporte e Manutenção',
        Des: "Após a entrega do projeto, nós oferecemos suporte contínuo e serviços de manutenção, garantindo que o software continue funcionando corretamente e atendendo às necessidades da sua empresa. Essa assistência é crucial para resolver eventuais problemas e realizar atualizações regulares, permitindo que você se mantenha competitivo em um ambiente de negócios em constante mudança.",
    },
    {
        id: '05',
        Title: 'Riscos Reduzidos',
        Des: "A nossa experiência ajuda a mitigar riscos associados ao desenvolvimento de software. Com um histórico comprovado de entrega de projetos dentro do prazo, você pode confiar que a parceria trará resultados confiáveis. A gestão de riscos é uma parte fundamental de sua abordagem, permitindo que você se concentre em suas atividades principais enquanto nós cuidamos da complexidade do desenvolvimento.",
    },
]

const Testimonial = () => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);

    useEffect(() => {
        if (swiperRef.current && prevRef.current && nextRef.current) {
            swiperRef.current.params.navigation.prevEl = prevRef.current;
            swiperRef.current.params.navigation.nextEl = nextRef.current;
            swiperRef.current.navigation.init();
            swiperRef.current.navigation.update();
        }
    }, []);

    return (

        <div className="row">
            <div className="col-lg-4">
                <div className="deals_winner_customers">
                    <h3 className="review_title"><mark>Mercado Global de Desenvolvimento de Software</mark></h3>
                    <h5 className="review_commtent">
                         De acordo com um relatório da Gartner, <mark>70%</mark> das empresas utilizam serviços de TI terceirizados.
                    </h5>
                    <h5 className="review_commtent">
                    Uma pesquisa da Deloitte revelou que aproximadamente <mark>59%</mark> das empresas estão aumentando seus investimentos em terceirização de TI
                    </h5>
                </div>
            </div>
            <div className="col-lg-8">
                <div className="review_onecol_wrapper">
                    <div className="review_onecol_carousel">
                        <Swiper
                            // install Swiper modules
                            modules={[Navigation]}
                            spaceBetween={50}
                            slidesPerView={1}
                            loop={true}
                            speed={1800}
                            parallax={true}
                            ref={swiperRef}
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper;
                            }}
                        >
                            {testimonial.map((testimonial, tsm) => (
                                <SwiperSlide key={tsm}>
                                    <div className="review_block_2">
                                        <h3 className="review_title">{testimonial.Title}</h3>
                                        <p className="review_commtent">
                                            {testimonial.Des}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>


                        <div className="carousel_arrows_nav">
                            <button ref={prevRef} type="button" className="r1cc-swiper-button-prev">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <button ref={nextRef} type="button" className="r1cc-swiper-button-next">
                                <i className="fa-solid fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;