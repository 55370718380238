import React, { useState } from 'react';
import Bg from '../../../images/shapes/shape_title_under_line.svg';
import shape1 from '../../../images/shapes/shape_line_2.svg';
import shape2 from '../../../images/shapes/shape_line_3.svg';
import shape3 from '../../../images/shapes/shape_line_4.svg';
import shape4 from '../../../images/shapes/shape_space_3.svg';

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';
import TechnologySection from '../TechnologySection/TechnologySection';
import Testimonial from '../Testimonial/Testimonial';

const FaqSection = (props) => {
  const [open, setOpen] = useState('1');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <section className='process_technology_review_section bg-light section_decoration' id="methodology">
      <div className='container'>
        <div className='row align-items-center justify-content-lg-between'>
          <div className='col-lg-6'>
            <div className='heading_block'>
              <div
                className='heading_focus_text has_underline d-inline-flex'
                style={{ backgroundImage: `url(${Bg})` }}
              >
                Como trabalhamos
              </div>
              <h2 className='heading_text mb-0'>
                Metodologia de <mark>Projeto</mark>
              </h2>
            </div>
            <Accordion
              open={open}
              toggle={toggle}
              className='accordion'
              id='service_process_faq'
            >
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='1'>
                  01. Fase de Discovery
                </AccordionHeader>
                <AccordionBody accordionId='1' className='acc_body'>
                  <p className='m-0'>
                    Nesta etapa, buscamos entender profundamente as necessidades
                    do cliente e os requisitos do projeto. Realizamos pesquisas,
                    entrevistas e análises para mapear os objetivos e desafios,
                    garantindo uma visão clara do problema a ser resolvido.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='2'>
                  02. Planejamento e Design
                </AccordionHeader>
                <AccordionBody accordionId='2' className='acc_body'>
                  <p className='m-0'>
                    Criamos um plano detalhado do projeto, definindo prazos,
                    escopo e recursos. Paralelamente, desenvolvemos wireframes e
                    protótipos, desenhando a interface e garantindo uma
                    experiência do usuário otimizada, sempre de acordo com as
                    melhores práticas de design.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='3'>
                  03. Desenvolvimento
                </AccordionHeader>
                <AccordionBody accordionId='3' className='acc_body'>
                  <p className='m-0'>
                    A equipe de desenvolvimento começa a transformar o design em
                    um produto funcional. Utilizamos as tecnologias mais
                    adequadas para garantir performance, escalabilidade e
                    segurança, seguindo práticas ágeis para entregar
                    funcionalidades de forma iterativa.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='4'>04. Testes e QA</AccordionHeader>
                <AccordionBody accordionId='4' className='acc_body'>
                  <p className='m-0'>
                    Após o desenvolvimento, realizamos uma série de testes para
                    garantir a qualidade do software. Testamos funcionalidade,
                    usabilidade, segurança e compatibilidade, corrigindo
                    eventuais erros e garantindo o desempenho esperado.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='accordion-item'>
                <AccordionHeader targetId='5'>
                  05. Deployment e Homologação
                </AccordionHeader>
                <AccordionBody accordionId='5' className='acc_body'>
                  <p className='m-0'>
                    Com o produto aprovado, fazemos a implantação em ambiente de
                    produção e passamos pela fase de homologação, onde o cliente
                    valida o sistema para garantir que todas as expectativas
                    foram atendidas. Durante essa fase, damos o suporte
                    necessário para ajustes finais.
                  </p>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <div className='col-lg-5'>
            <Accordion
              open={open}
              toggle={toggle}
              className='accordion'
              id='service_process_faq'
            >
              <ul className='content_layer_group unordered_list_block text-center'>
                <li>
                  <AccordionHeader targetId='1'>
                    <span>Fase de Discovery</span>
                  </AccordionHeader>
                </li>
                <li>
                  <AccordionHeader targetId='2'>
                    <span>Planejamento e Design</span>
                  </AccordionHeader>
                </li>
                <li>
                  <AccordionHeader targetId='3'>
                    <span>Desenvolvimento</span>
                  </AccordionHeader>
                </li>
                <li>
                  <AccordionHeader targetId='4'>
                    <span>Testes e QA</span>
                  </AccordionHeader>
                </li>
                <li>
                  <AccordionHeader targetId='5'>
                    <span>Deployment e Homologação</span>
                  </AccordionHeader>
                </li>
              </ul>
            </Accordion>
          </div>
        </div>
        <TechnologySection />
        <Testimonial />
      </div>

      <div className='decoration_item shape_image_1'>
        <img src={shape1} alt='Techco Shape' />
      </div>
      <div className='decoration_item shape_image_2'>
        <img src={shape2} alt='Techco Shape' />
      </div>
      <div className='decoration_item shape_image_3'>
        <img src={shape3} alt='Techco Shape' />
      </div>
      <div className='decoration_item shape_image_4'>
        <img src={shape4} alt='Techco Shape' />
      </div>
    </section>
  );
};
export default FaqSection;
